<template>
  <c-transition>
    <c-popover
      v-if="message"
      class="login-warning"
      element-spacing="10"
      position="top"
      v-bind="$attrs"
    >
      <p class="message">{{ message }}</p>
    </c-popover>
  </c-transition>
</template>

<script>
export default {
  props: {
    message: String
  }
}
</script>

<style lang="scss">
.login-warning {
  display: flex !important;
  z-index: $z-index-6 !important;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: calc(100% - 20px);
  max-width: 400px;
  border-radius: 5px;
  background: set-linear-gradient(135deg, $negative-color-map);
  padding: 10px 20px;

  @include responsive(xs-mobile, mobile) {
    transform: translateY(90px);
  }

  & > .message {
    height: auto;
    text-align: center;
    color: #FFFFFF;
  }

  &::after {
    background: inherit;
    content: "\00a0";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -8px;
    width: 16px;
    height: 18px;
    clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0 50%);
  }

  &::before { display: none; }
}
</style>
